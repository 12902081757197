import React, { useEffect, useState } from "react";

import BaseInputV2, { IBaseInputProps } from "./BaseInputV2";

interface IBaseTextInputProps extends IBaseInputProps {
  value?: string;
}

const BaseTextInput: React.FunctionComponent<IBaseTextInputProps> = ({
  spellCheck,
  autoCorrect,
  children,
  ...props
}) => {
  return (
    <BaseInputV2
      type="text"
      spellCheck={spellCheck}
      autoCorrect={autoCorrect}
      {...props}
    >
      {children}
    </BaseInputV2>
  );
};

export default BaseTextInput;
