import React, { useState } from "react";

import { POLLFORM_SET_FORM_VALUE_TYPE } from "actions/pollform";
import PrimaryButton from "components/common/Buttons/Primary";
import TagButton from "components/common/Buttons/Tag";
import BaseForm from "components/common/Forms/BaseFrom";
import BaseTextInput from "components/common/Inputs/BaseTextInput";
import TopSection from "components/Sections/Top";
import { PageProps } from "gatsby";
import { navigate } from "gatsby-link";
import usePollFormContext from "hooks/common/usePollFormContext";
import { AiFillBulb } from "react-icons/ai";

const samples = [
  { value: "12月挑戰 - 聖誕假做咩?" },
  { value: "煮咩晚餐？由你話事👀🍽" },
  { value: "拍咩由你話事!?" },
  { value: "我明天交給你決定" },
  { value: "你決定今個周末的節目" },
  { value: "我應該邀請哪一位嘉賓?" },
  { value: "下一個推出的產品？" },
  { value: "你敢挑戰，我敢找數" },
  { value: "What is next challenge?" },
  { value: "Topic for next video?" },
];

interface IProps extends PageProps<any, any, any> {
}

const DareMeTitlePage: React.FunctionComponent<IProps> = ({ location }) => {
  const search = new URLSearchParams(location.search);
  const value = search.get("value") || "";
  const [state, setState] = useState(value || "");
  const { pollForm, pollFormDispatch } = usePollFormContext();

  const onTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;

    setState(target.value);
  }

  const onTagClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    const target = e.target as HTMLButtonElement;

    setState(target.value);
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    
    try {
      const target = e.target as any;
    
      pollFormDispatch({
        type: POLLFORM_SET_FORM_VALUE_TYPE,
        payload: { ...pollForm, [target.title!.name]: target.title.value},
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TopSection
        title={"DareMe Title"}
        leftComponent={true}
        rightComponent={<AiFillBulb className="text-success-600" />}
      />
      <BaseForm className="min-h-[80vh] max-w-2xl flex flex-col content-between mx-auto px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:py-8 " onSubmit={onSubmit}>
        <BaseTextInput
          name="title"
          value={state}
          onChange={onTextChange}
          maxLength={40}
        />
        <div className="flex-grow flex flex-col justify-center items-start">
          <h4 className="text-neutral-800">What other creators dared?</h4>

          {samples.map((sample) => {
            return (
              <>
                <br />
                <TagButton value={sample.value} onClick={onTagClick}>{sample.value}</TagButton>
              </>
            );
          })}
          </div>

        <PrimaryButton type="submit" className="w-full mt-4 px-3 py-2 bottom-0" disabled={state.length === 0}>
          Save
        </PrimaryButton>
      </BaseForm>
    </>
  );
};

export default DareMeTitlePage;
