import React, { useContext } from "react";

import { PollFormContext } from "contexts/PollForm";


const usePollFormContext = () => {
  return useContext(PollFormContext);
};

export default usePollFormContext;
