import React from "react";

import { Disclosure } from '@headlessui/react'

type IReactFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> 

export interface IBaseFormProps extends IReactFormProps {

}

const BaseForm: React.FunctionComponent<IBaseFormProps> = ({
  children,
  ...props
}) => {

  return (
    <Disclosure as="form" data-netlify="true" data-netlify-honeypot="bot-field" {...props}>
      <input type="hidden" className="hidden" name="form-name" defaultValue={props?.name} value={props?.name} />
      {children}
    </Disclosure>
  );
};

export default BaseForm;
