import React from "react";

import { IBaseButtonProps } from "./BaseButton";
import PrimaryButton from "./Primary";

interface ITagButtonProps extends IBaseButtonProps {}

const TagButton: React.FunctionComponent<ITagButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <PrimaryButton
      className={`btn-pill btn-outline ${className}`}
      {...props}
    >
      {children}
    </PrimaryButton>
  );
};

export default TagButton;
